import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace DotNetSpatialServiceAPI {

  export class ConvertFromDxfFile implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data

    constructor(public params: object = {}) {
      this.path = '/netcorespatialservice/convert/'
    }
  }

  export class ConvertToGeoTiff implements APIRequest<string> {
    response: string;
    path = '/netcorespatialservice/raster/base64/convert';
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    blob = true
    fileName = 'screen.tiff'

    constructor(public params: object = {}) {
    }
  }
}
