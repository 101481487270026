<template>
  <div>
    <el-dialog
      top="7vh"
      title="Импорт файла"
      :modal="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="showFileWindow">
      <import-file-d-x-f-form
        :userCSs="userCSs"
        :importLayerGeometryField="importLayerGeometryField"
        @features-imported="getGeoJSONFromImport"
      ></import-file-d-x-f-form>
    </el-dialog>
    <el-dialog
      top="7vh"
      title="Импорт строки WKT"
      :modal="true"
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="showWKTWindow">
      <string-w-k-t-form
        :userCSs="userCSs"
        @import-wkt-string="importWKTString"
      ></string-w-k-t-form>
    </el-dialog>
  </div>
</template>

<script>
  import ImportFileDXFForm from './fileDXFForm'
  import StringWKTForm from './stringWKTForm'

  export default {
    name: 'importFeatures',
    components: { ImportFileDXFForm, StringWKTForm },
    props: {
      userCSs: {
        type: Array
      },
      importLayerGeometryField: {
        type: String,
        default: null
      }
    },
    data: function () {
      return {
        showFileWindow: false,
        showWKTWindow: false
      }
    },
    methods: {
      getGeoJSONFromImport (featureCollection, srsId) {
        this.$emit('import-geojson-features', featureCollection, srsId)
        this.showFileWindow = false
      },
      showImportFileWindow () {
        this.showFileWindow = true
      },
      showImportWKTWindow () {
        this.showWKTWindow = true
      },
      importWKTString (stringWKT, srsId) {
        this.$emit('import-wkt-string', stringWKT, srsId)
        this.showWKTWindow = false
      }
    }
  }
</script>

<style scoped>

</style>
